import React from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

import {
    container,
    form,
    inputs,
    successMessage,
    errorText,
    button,
} from "./contact-form.module.scss";
import { postContact } from "../../api/contact";
import { formatFormikErrors } from "../../api/utils";
import { config } from "../../config";

import Input from "../atoms/form/input";
import Button from "../atoms/button";
import Checkbox from "../atoms/form/checkbox";
import FormikForm from "../hoc/formik-form";
import Error from "../atoms/form/error";

const { required, requiredConsent, email } = config.messages.form;
const { loading, success, fail } = config.statusMap;

interface IContactFormProps {
    className?: string;
}

const initialValues = {
    name: "",
    email: "",
    content: "",
    global: "",
    consent: false,
};

const getValidationSchema = Yup.object({
    name: Yup.string().required(required),
    email: Yup.string().email(email).required(required),
    content: Yup.string().required(required),
    consent: Yup.boolean().required(requiredConsent).oneOf([true], requiredConsent),
});

const ContactForm: React.FC<IContactFormProps> = ({ className = "" }) => {
    const { t } = useI18next();

    const handleSubmit = async (
        values: FormikValues,
        helpers: FormikHelpers<any>
    ): Promise<any> => {
        helpers.setStatus(loading);

        const data = {
            name: values.name,
            email: values.email,
            content: values.content,
        };

        postContact(data)
            .then(() => {
                helpers.setStatus(success);
            })
            .catch((error) => {
                helpers.setStatus(fail);
                helpers.setErrors(formatFormikErrors(error));
            });
    };

    return (
        <div className={`${container} ${className}`}>
            <Formik
                initialValues={initialValues}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <FormikForm
                        className={form}
                        formik={formik}
                        successMessage={
                            <p className={successMessage}>
                                Twoja wiadomość została pomyślnie wysłana!
                            </p>
                        }
                    >
                        <div className={inputs}>
                            <Input name="name" placeholder={t("form.label.name")} />
                            <Input name="email" placeholder={t("form.label.email")} />
                            <Input
                                name="content"
                                as="textarea"
                                rows={8}
                                placeholder={t("form.label.message")}
                            />
                            <Checkbox name="consent">
                                <ReactMarkdown
                                    // @ts-ignore
                                    rehypePlugins={[rehypeRaw]}
                                >
                                    {t("form.consent.privacy")}
                                </ReactMarkdown>
                            </Checkbox>
                            {formik.errors.global && (
                                <div className={errorText}>
                                    <Error>{formik.errors.global}</Error>
                                </div>
                            )}
                        </div>
                        <Button color="primary" type="filled" htmlType="submit" className={button}>
                            {t("button.send")}
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
