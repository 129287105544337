import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { ContactPage } from "schema-dts";

import Portrait from "../../assets/images/svg/portrait.svg";
import Mail from "../../assets/images/svg/mail.svg";

import {
    container,
    portrait,
    address,
    heading,
    contact,
    contactForm,
    wrapper,
    validate,
    subtitle,
} from "./contact.module.scss";
import usePageMetadata from "../../hooks/use-page-metadata";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";

import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";
import ContactForm from "../organisms/contact-form";

export interface IContactProps extends IPageBase {
    readonly data: {
        site: ISite;
    };
}

const Contact = ({ data, pageContext }: IContactProps) => {
    const { pageTitle, siteMetadata, defaultStructuredData } = usePageMetadata(
        pageContext,
        data.site
    );

    const structuredData = useMemo(() => {
        const contactPage: ContactPage = {
            "@type": "ContactPage",
            "@id": `${siteMetadata.url}/#website`,
        };
        return {
            "@graph": [...defaultStructuredData["@graph"], contactPage],
        };
    }, [defaultStructuredData]);

    return (
        <MainLayout
            SEOProps={{
                title: pageTitle,
                structuredData,
            }}
        >
            <SectionWrapper bgColor={"blue-light"}>
                <Section className={container}>
                    <SectionWrapper bgColor={"white"} className={wrapper}>
                        <Section
                            column={"left"}
                            columnTablet={"regular"}
                            columnPhone={"regular"}
                            className={portrait}
                        >
                            <Portrait />
                        </Section>
                        <Section
                            column={"left"}
                            columnTablet={"regular"}
                            columnPhone={"regular"}
                            className={address}
                        >
                            <span className={contact}>
                                <Mail />
                                <p>
                                    <a href="mailto:kontakt@fashionfairy.pl">
                                        kontakt@fashionfairy.pl
                                    </a>
                                </p>
                            </span>
                        </Section>
                        <Section
                            column={"right"}
                            columnTablet={"regular"}
                            columnPhone={"regular"}
                            className={validate}
                        >
                            <Title
                                size={"large"}
                                subtitle={"kontakt"}
                                subtitleClassName={subtitle}
                                className={heading}
                            >
                                <span>Napisz do nas</span>
                            </Title>
                            <ContactForm className={contactForm} />
                        </Section>
                    </SectionWrapper>
                </Section>
            </SectionWrapper>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default Contact;
