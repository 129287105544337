import axios from "axios";
import { config } from '../config';

interface ContactData {
    email: string,
    content: string,
}

export function postContact(data: ContactData): Promise<any> {
    return axios.post(config.endpoints.contact, data, { withCredentials: true })
}




