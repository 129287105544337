import React from "react";
import { Field, ErrorMessage } from "formik";

import { container, label, input, checkbox, checkmark, labelContent } from './checkbox.module.scss';
import CheckIcon from '../../../assets/images/svg/checkmark.svg';

import Error from "./error";

export interface ICheckboxProps {
    className?: string,
    name: string,
    identifier?: string | number,
}

const Checkbox: React.FC<ICheckboxProps & Record<string, any>> = ({
    className = '',
    name,
    identifier = '',
    children,
    ...rest
}) => {
    return (
        <div className={`${container} ${className}`}>
            <label htmlFor={`${name}${identifier}`} className={label}>
                <Field
                    type="checkbox"
                    id={`${name}${identifier}`}
                    name={name}
                    className={input}
                    {...rest}
                />
                <span className={checkbox}>
                    <CheckIcon className={checkmark} />
                </span>
                <span className={labelContent}>
                    {children}
                </span>
            </label>
            <ErrorMessage name={name} component={Error} />
        </div>
    )
};

export default Checkbox;